import { useWindowSize } from "@react-hook/window-size";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { UserContext } from "../../contexts/UserContext";
import "./Game.css";
function Game() {
  const userContext = useContext(UserContext);
  const [width, height] = useWindowSize();
  const {
    unityProvider,
    isLoaded,
    loadingProgression,
    sendMessage,
    addEventListener,
    removeEventListener,
  } = useUnityContext({
    loaderUrl: "1.3.0/Build/1.3.0.loader.js",
    dataUrl: "1.3.0/Build/1.3.0.data",
    frameworkUrl: "1.3.0/Build/1.3.0.framework.js",
    codeUrl: "1.3.0/Build/1.3.0.wasm",
  });
  const [isSubscribed, setIsSubscribed] = useState(false);

  // function sendNickname() {
  //   console.log("Sending nickname to game app:", userContext.nickname);
  //   sendMessage("CommunicationManager", "SetNickname", userContext.nickname);
  // }

  const handleSubmitScore = useCallback(
    async (score) => {
      console.log("submitting score");
      try {
        if (Number(score) > 0) {
          await axios.post(
            `https://faas-fra1-afec6ce7.doserverless.co/api/v1/web/fn-887b91ff-674d-4d4e-9430-f571aee304c3/main/submitScore`,
            {
              score: score,
              username: userContext.nickname,
            }
          );
          console.log("score successfully submitted");
        } else {
          console.log("score not submitted, less or equal 0");
        }
        sendMessage("CommunicationManager", "GoToLeaderboard");
      } catch (err) {
        console.log("error while submitting score", err);
      }
    },
    [sendMessage, userContext.nickname]
  );

  useEffect(() => {
    if (isLoaded) {
      console.log("Game loaded, starting...");
      // sendNickname();
      if (!isSubscribed) {
        setIsSubscribed(true);
        console.log("metto il subscribe");
        addEventListener("SubmitScore", handleSubmitScore);
      }
    }

    return () => {
      if (isSubscribed) {
        console.log("tolgo il subscribe dal loaded");
        setIsSubscribed(false);
        removeEventListener("SubmitScore", handleSubmitScore);
      }
    };
  }, [isLoaded]);

  useEffect(() => {
    return () => {
      if (isSubscribed) {
        console.log("tolgo il subscribe e basta");
        setIsSubscribed(false);
        removeEventListener("SubmitScore", handleSubmitScore);
      }
    };
  }, []);

  return (
    <>
      {!isLoaded && (
        <div className="gamepage-loading">
          <p>Loading... {Math.round(loadingProgression * 100)}%</p>
        </div>
      )}
      <div
        className="gamepage"
        style={{ visibility: isLoaded ? "visible" : "hidden" }}
      >
        <Unity
          style={{
            height,
            width: width > (height * 3) / 4 ? (height * 3) / 4 : width,
          }}
          unityProvider={unityProvider}
        />
      </div>
    </>
  );
}

export default Game;
