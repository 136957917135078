import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { UserContext } from "./contexts/UserContext";
import FormPage from "./pages/FormPage/FormPage";
import Game from "./pages/GamePage/Game";
import Leaderboard from "./pages/LeaderboardPage/Leaderboard";
import Scenario from "./pages/ScenarioPage/Scenario";

function App() {
  const [nickname, setNickname] = useState("");

  return (
    <div className="App">
      <UserContext.Provider
        value={{
          nickname,
          setNickname: (val) => {
            setNickname(val);
          },
        }}
      >
        <Routes>
          <Route path="/" element={<FormPage />} />
          <Route path="scenario" element={<Scenario />} />
          <Route path="game" element={<Game />} />
          <Route path="leaderboard" element={<Leaderboard />} />
        </Routes>
      </UserContext.Provider>
    </div>
  );
}

export default App;
