import axios from "axios";
import React, { useEffect, useState } from "react";
import "./Leaderboard.css";

function Leaderboard() {
  const [entries, setEntries] = useState([]);
  // const [guestSession, setGuestSession] = useState();
  const [fetchLoop, setFetchLoop] = useState();

  useEffect(() => {
    async function fetchScoresDO() {
      try {
        const scoresRes = await axios.post(
          `https://faas-fra1-afec6ce7.doserverless.co/api/v1/web/fn-887b91ff-674d-4d4e-9430-f571aee304c3/main/getScores`,
          {
            count: 9,
          }
        );
        setEntries(scoresRes.data.items);
        // setTimeout(() => {
        //   fetchScoresDO();
        // }, fetchTimer);
      } catch (err) {
        // setTimeout(() => {
        //   fetchScoresDO();
        // }, fetchTimer);
      }
    }
    if (!fetchLoop) {
      fetchScoresDO().then(() =>
        setFetchLoop(
          setInterval(async () => {
            await fetchScoresDO();
          }, 20000)
        )
      );

      return function cleanup() {
        clearInterval(fetchLoop);
      };
    }

    return;
  }, []);

  // async function makeGuestSession() {
  //   const guestTokenRes = await axios.post(
  //     "https://api.lootlocker.io/game/v2/session/guest",
  //     {
  //       game_key: process.env.LOOTLOCKER_GAME_KEY,
  //       game_version: process.env.LOOTLOCKER_GAME_VERSION,
  //     }
  //   );
  //   setGuestSession(guestTokenRes.data);
  // }

  // async function fetchScores() {
  //   const scoresRes = await axios.get(
  //     `https://api.lootlocker.io/game/leaderboards/${process.env.LOOTLOCKER_LEADERBOARD_KEY}/list?count=${args.count}`,
  //     {
  //       headers: {
  //         "x-session-token": guestSession.session_token,
  //       },
  //     }
  //   );
  // }

  const entriesHtml = entries.map((entry, index) => (
    <tr key={`${index}-${entry.metadata.replace(" ", "")}-${entry.score}`}>
      <td>{index + 1}</td>
      <td>{entry.metadata}</td>
      <td>{entry.score}</td>
    </tr>
  ));

  return (
    <div className="leaderboardpage">
      <div className="logo">
        <img
          src="./bioritmon-title.png"
          draggable="false"
          alt="bioritmon logo"
        />
      </div>
      {/* <Link to={"leaderboard"} className="leaderboardBtn">
        TORNA AL GIOCO
      </Link> */}
      <div className="leaderboard-container">
        <table className="leaderboard-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Giocatore</th>
              <th>Punteggio</th>
            </tr>
          </thead>
          <tbody>{entriesHtml}</tbody>
        </table>
      </div>
    </div>
  );
}

export default Leaderboard;
