import axios from "axios";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import "./FormPage.css";

function FormPage() {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailLogin, setEmailLogin] = useState("");
  const [nickname, setNickname] = useState("");
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
  const [registerMsg, setRegisterMsg] = useState("");
  const [registerStatus, setRegisterStatus] = useState("not_sent");
  // const [guestSessionStatus, setGuestSessionStatus] = useState("not_sent");
  const [loginStatus, setLoginStatus] = useState("not_sent");
  const [isLogin, setIsLogin] = useState(false);

  const isRegisterFormValid =
    firstName !== "" &&
    lastName !== "" &&
    email !== "" &&
    nickname !== "" &&
    privacyPolicyAccepted;
  const isLoginFormValid = emailLogin !== "";

  async function submitRegister() {
    try {
      setRegisterStatus("pending");
      const checkUserRes = await axios.get(
        `https://script.google.com/macros/s/AKfycbxFq8tHyWuV6b-BJ1S7zjflPnWoOye-7948DNQmqSD6Wd3t5yAx_rRa3VRCmtaXnMX4vQ/exec?email=${email}`
      );

      if (!checkUserRes.data.exists) {
        const writeToSheetRes = await axios.post(
          "https://script.google.com/macros/s/AKfycbxFq8tHyWuV6b-BJ1S7zjflPnWoOye-7948DNQmqSD6Wd3t5yAx_rRa3VRCmtaXnMX4vQ/exec",
          {
            firstName: firstName,
            lastName: lastName,
            email: email,
            nickname: nickname,
          },
          {
            headers: {
              "Content-Type": "text/plain;charset=utf-8",
            },
          }
        );
        const sendEmailRes = await axios.post(
          "https://faas-fra1-afec6ce7.doserverless.co/api/v1/web/fn-887b91ff-674d-4d4e-9430-f571aee304c3/main/sendEmail",
          {
            userEmail: email,
          }
        );

        if (writeToSheetRes.data.inserted) {
          setRegisterStatus("success");
          userContext.setNickname(nickname);
          // const token = await requestGuestSession();
          // if (token) {
          navigate("/scenario");
          // }
        } else {
          setRegisterStatus("failure");
          setRegisterMsg(
            "Non é stato possibile registrare l'account. Per favore, riprova"
          );
        }
      } else {
        setRegisterStatus("exists");
        setRegisterMsg(
          "L'email é giá in uso, effettua il login o usane un'altra"
        );
      }
    } catch (err) {
      setRegisterStatus("failure");
      setRegisterMsg(
        "Non é stato possibile registrare l'account. Per favore, riprova"
      );
    }
  }

  async function submitLogin() {
    setLoginStatus("pending");
    const res = await axios.get(
      `https://script.google.com/macros/s/AKfycbxFq8tHyWuV6b-BJ1S7zjflPnWoOye-7948DNQmqSD6Wd3t5yAx_rRa3VRCmtaXnMX4vQ/exec?email=${emailLogin}`
    );

    if (!res.data.exists) {
      setLoginStatus("not_exist");
    } else {
      userContext.setNickname(res.data.nickname);
      // const token = await requestGuestSession();
      setLoginStatus("success");
      // if (token) {
      navigate("/scenario");
      // }
    }
  }
  // async function requestGuestSession() {
  //   try {
  //     setGuestSessionStatus("pending");
  //     const res = await axios.get(
  //       `https://api.lootlocker.io/game/v2/session/guest`
  //     );

  //     if (res.data.success) {
  //       setGuestSessionStatus("success");
  //       userContext.setSessionToken(res.data.session_token);
  //       return res.data.session_token;
  //     } else {
  //       setGuestSessionStatus("failure");
  //       setRegisterMsg(
  //         "É stato impossibile generare una sessione di gioco, per favore ricarica la pagina ed effettua il login"
  //       );
  //       return null;
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     setGuestSessionStatus("failure");
  //     setRegisterMsg(
  //       "É stato impossibile generare una sessione di gioco, per favore ricarica la pagina ed effettua il login"
  //     );
  //     return null;
  //   }
  // }

  const registerCtn = (
    <div className="form-container">
      <div className="bio-img"></div>
      <h2>Registrati</h2>

      <button className={`loginBtn`} onClick={() => setIsLogin(true)}>
        LOGIN
      </button>

      <div className="inputs">
        <div className="input-wr">
          <h2>Nome</h2>
          <input
            type="text"
            name="Nome"
            id="nome"
            value={firstName}
            onInput={(e) => {
              setFirstName(e.target.value);
              if (registerMsg !== "") setRegisterMsg("");
            }}
          />
        </div>
        <div className="input-wr">
          <h2>Cognome</h2>
          <input
            type="text"
            name="Cognome"
            id="cognome"
            value={lastName}
            onInput={(e) => {
              setLastName(e.target.value);
              if (registerMsg !== "") setRegisterMsg("");
            }}
          />
        </div>
        <div className="input-wr">
          <h2>E-mail</h2>
          <input
            type="email"
            name="E-mail"
            id="email"
            value={email}
            onInput={(e) => {
              setEmail(e.target.value);
              if (registerMsg !== "") setRegisterMsg("");
            }}
          />
        </div>
        <div className="input-wr">
          <h2>Nickname</h2>
          <input
            type="text"
            name="Nickname"
            id="nickname"
            value={nickname}
            onInput={(e) => {
              setNickname(e.target.value);
              if (registerMsg !== "") setRegisterMsg("");
            }}
          />
        </div>
      </div>

      <div className="footerCtn">
        <div className="privacyPolicyCtn">
          <label>
            <a
              href="https://bioritmon.yomi.digital/privacy-policy.pdf"
              target="_blank"
              rel="noreferrer"
            >
              leggi le nostre privacy policy
            </a>
          </label>
          <input
            type="checkbox"
            name="privacy-policy"
            id="privacy-policy"
            checked={privacyPolicyAccepted}
            onChange={(e) => setPrivacyPolicyAccepted(e.target.checked)}
          />
        </div>
        <button
          className={`toScenarioBtn ${
            isRegisterFormValid && registerStatus !== "pending"
              ? null
              : "my-disabled"
          }`}
          onClick={() => submitRegister()}
        >
          ACCETTA E PROSEGUI
        </button>
      </div>

      <p className="formErrorMsg">{registerMsg}</p>
    </div>
  );

  const loginCtn = (
    <div className="form-container">
      <div className="bio-img"></div>

      <h2>LOGIN</h2>

      <button className={`loginBtn`} onClick={() => setIsLogin(false)}>
        REGISTRATI
      </button>

      <div className="inputs">
        <div className="input-wr">
          <h2>E-mail</h2>
          <input
            className={`${loginStatus === "not_exist" ? "errorField" : null}`}
            type="email"
            name="E-mail"
            id="email"
            value={emailLogin}
            onInput={(e) => {
              if (loginStatus !== "not_sent") setLoginStatus("not_sent");
              setEmailLogin(e.target.value);
            }}
          />
        </div>
      </div>

      <button
        className={`toScenarioBtn ${
          isLoginFormValid && loginStatus !== "pending" ? null : "my-disabled"
        }`}
        onClick={() => submitLogin()}
      >
        PROSEGUI
      </button>
    </div>
  );

  return (
    <div className="formpage">
      <div className="logo">
        <img
          src="./bioritmon-title.png"
          draggable="false"
          alt="bioritmon logo"
        />
      </div>
      <Link to={"leaderboard"} className="leaderboardBtn">
        CLASSIFICA
      </Link>
      <div className="text-center">
        <h2>Lo sapevi che</h2>
        <div className="text-container">
          <p className="form-page-first-paragraph">
            {
              "Il tuo MICROBIOTA INTESTINALE è un importante <<centro attivatore>> della tua energia e delle tue difese immunitarie?"
            }
          </p>
          <p>
            Gioca con Bioritmon e scopri come favorire l'equilibrio del
            microbiota e supportare le tue energie e le tue difese!
          </p>
        </div>
      </div>

      {isLogin ? loginCtn : registerCtn}
    </div>
  );
}

export default FormPage;
