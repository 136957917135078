import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Scenario.css";

function Scenario() {
  const [step, setStep] = useState(1);

  return (
    <div className="scenario1">
      <div className="logo">
        <img
          src="./bioritmon-title.png"
          draggable="false"
          alt="bioritmon logo"
        />
      </div>

      <h1>Lo scenario di gioco!</h1>
      <div className="scenario-container">
        <div className="bio-img">
          <img src="./microbio.png" alt="microbiota"></img>
        </div>
        {step === 1 ? (
          <>
            <img
              className="scenario-1-img"
              src="graph.png"
              alt="Grafico microbiota"
            />
            <p className="scenario-1-p">
              Il microbiota intestinale é l'insieme di microrganismi che popola
              il nostro intestino
            </p>
            <button className="scenario-1-btn" onClick={() => setStep(2)}>
              NEXT
            </button>
          </>
        ) : step === 2 ? (
          <>
            <img className="scenario-2-img" src="player.png" alt="Microbiota" />
            <img
              className="scenario-2-img-arrow"
              src="Arrow_long.png"
              alt="Freccia"
            />
            <h1 className="scenario-2-title">IMMUNOMETABOLISMO</h1>
            <p className="scenario-2-p">
              Quando il microbiota é in equilibrio potenzia il processo di
              IMMUNOMETABOLISMO
            </p>
            <button className="scenario-2-btn" onClick={() => setStep(3)}>
              NEXT
            </button>
          </>
        ) : step === 3 ? (
          <>
            <h1 className="scenario-3-title">IMMUNOMETABOLISMO</h1>
            <div className="scenario-3-body">
              <img
                className="scenario-3-img"
                src="scenario-3.png"
                alt="Grafico step 3 scenario"
              />
            </div>
            <button className="scenario-3-btn" onClick={() => setStep(4)}>
              NEXT
            </button>
          </>
        ) : (
          <>
            <h2>
              IL TUO MICROBIOTA HA UN NUOVO ALLEATO PER COMPIERE LA SUA MISSIONE
            </h2>
            <p className="scenario-4-p">
              Bioritmon Energy Defend grazie alla sua formula favorisce
              l'equilibrio del microbiota e supporta la tua energia e le tue
              difese!
            </p>
            <img
              className="scenario-4-img"
              src="bioritmon-box.png"
              alt="Scatola di Bioritmon"
            />
            <Link className="scenario-4-btn" to={"/game"}>
              NEXT
            </Link>
          </>
        )}
      </div>
    </div>
  );
}

export default Scenario;
